import React from 'react';
import sizes from 'react-sizes';
import styled from 'styled-components';
import {
  Layout,
  ContactForm,
  SEO,
  InstagramGallery,
  Title,
  Map,
  NewsletterSignUp,
  StreetPhotoAndContactDetails
} from 'components';

const ContactUs = ({ location }) => {
  return (
    <Layout location={location}>
      <SEO
        pageSpecificTitle="Contact Us"
        pageSpecificDescription="Got a question about any of our services? Don't hesitate to get in touch!"
        pathname={location.pathname}
      />
      <Padding />
      <Title text="Get In Touch" pathname={location.pathname} />
      <StreetPhotoAndContactDetails />
      <Map />
      <ContactForm pathname={location.pathname} />
      {/* <Title text="Instagram Feed" /> */}
      {/* <InstagramGallery /> */}
      {/* <NewsletterSignUp /> */}
    </Layout>
  );
};

const Padding = styled.div`
  padding-top: 160px;
`;

export default sizes(({ width }) => ({ viewportWidth: width && width }))(ContactUs);
